<template>
  <dash-page-new
      :title="$t('InAppPurchases')"
      :subtitle="$t('MobileApps')"
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"
  >

    <template #header_action>
      <div v-if="navigation !== 'courses'"
          class="d-flex align-center">
        <ws-button-group
            v-model="selectedType"
            :items="deviceTypeSelect"
            class="mr-3"
        />
        <ws-button @click="openAddPrice" label="Add"></ws-button>
      </div>
      <ws-text-field
          v-else
          v-model="search"
          icon="mdi-magnify"
          :placeholder="$t('Search')"
      />

    </template>

    <template #default >
      <ws-navigation
          v-model="navigation"
          :items="navigationSelect"
          no-number
      >
        <template #item.prices>
          <ws-data-table
              :items="pricesSelectFiltered"
              :headers="pricesHeaders"
              :row-action="(item) => { openEditPrice(item) }"
          >

            <template #item.price="{item}">
              <h5>{{ item.price }} USD</h5>
            </template>

            <template #item.is_active="{item}">
              <v-icon :color="item.is_active ? wsSUCCESS : wsBACKGROUND">mdi-check-circle-outline</v-icon>
            </template>

          </ws-data-table>
        </template>

        <template #item.courses>
          <ws-data-table
              :items="courses"
              :headers="coursesHeaders"
              :search="search"
          >
            <template #item.name="{item}">
              <h5 class="wsACCENT">{{ item.name }} {{ BUSINESS_CURRENCY }}</h5>
            </template>

            <template #item.price="{item}">
              <h5 class="text-no-wrap">{{ item.price }} {{ BUSINESS_CURRENCY }}</h5>
            </template>

            <template #item.iphone_price="{item}">
              <ft-select
                  @input="setCoursePrice($event , item , 'IPHONE')"
                  :value="item.iphone_price ? item.iphone_price.value : null"
                  :items="applePricesSelect"
                  searchable
              >
                <h5 class="wsDARKER d-flex align-center text-no-wrap">
                  <span v-if="item.iphone_price && item.iphone_price.price">{{ item.iphone_price.price }} USD</span>
                  <span v-else class="wsDARKLIGHT">{{ $t('Choose') }}</span>
                  <v-icon :color="item.iphone_price ? wsACCENT : wsDARKLIGHT">mdi-menu-down</v-icon>
                </h5>
              </ft-select>

            </template>

            <template #item.android_price="{item}">
              <ft-select
                  :value="item.android_price ? item.android_price.value : null"
                  @input="setCoursePrice($event , item, 'ANDROID')"
                  :items="androidPricesSelect"
              >
                <h5 class="wsDARKER d-flex align-center text-no-wrap">
                  <span v-if="item.android_price && item.android_price.price">{{ item.android_price.price }} USD</span>
                  <span v-else class="wsDARKLIGHT">{{ $t('Choose') }}</span>
                  <v-icon :color="item.android_price ? wsACCENT : wsDARKLIGHT">mdi-menu-down</v-icon>
                </h5>
              </ft-select>

            </template>

            <template #item.is_setup="{item}">
              <v-icon :color="item.android_price || item.iphone_price ? wsSUCCESS : wsBACKGROUND">mdi-check-circle-outline</v-icon>
            </template>

          </ws-data-table>
        </template>


      </ws-navigation>
    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayPriceDialog"
          v-model="displayPriceDialog"
          :title="!entityData.id ? $t('AddMobileProductPrice') :  $t('EditMobileProductPrice')"
          placeholder="Educational Course"
          @save="addEditPrice"
          :hide-buttons="entityData.is_active"
      >
        <v-sheet v-if="entityData.is_active" :color="wsLIGHTCARD" class="pa-3 wsRoundedLight  mb-5" style="border : 1px solid var(--wsBACKGROUND)">
          <h5 class="wsACCENT font-weight-regular">{{ $t('MobileProductNotEditable') }} </h5>
        </v-sheet>
        <ws-text-field
          v-model="entityData.name"
          :label="$t('Name')"
          placeholder="Educational Course"
          :readonly="entityData.is_active"
      />
        <ws-text-field
            v-model="entityData.price"
            :label="$t('Price') + ', USD'"
            :placeholder="$t('EnterPrice')"
            :error="priceError"
            @input="priceError = false"
            append-icon="mdi-currency-usd"
            number
            class="mt-5"
            :readonly="entityData.is_active"
        />

        <ws-text-field
            v-model="entityData.bundle"
            :error="bundleError"
            @input="bundleError = false"
            label="Bundle"
            placeholder="ua.westudy.app.product_100"
            class="mt-5"
            :readonly="entityData.is_active"
        />

      </ws-dialog>
    </template>

  </dash-page-new>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "DashMobileAppsInAppPurchase",
  data() {
    return {
      displayPriceDialog : false,
      navigation : 'prices',
      entityData : {},
      pricesSelect : [],
      selectedType : 'IPHONE',
      bundleError : false,
      priceError : false,
      search : '',

      deviceTypeSelect : [
        { text : 'Apple' , value : 'IPHONE' },
        { text : 'Android' , value : 'ANDROID' },
      ],

      courses : [],

    }
  },
  computed : {
    applePricesSelect() {
      let items = this.COPY(this.pricesSelect).filter(el => el.device_type === 'IPHONE')
          .sort((a, b) => a.price - b.price)
          .map(el => ({value :el.id, text : (el.price + ' USD')}))
      items.unshift({ text : this.$t('Delete') , value : null })
      return items

    },
    androidPricesSelect() {
      let items = this.COPY(this.pricesSelect).filter(el => el.device_type === 'ANDROID')
          .sort((a, b) => a.price - b.price)
          .map(el => ({value :el.id, text : (el.price + ' USD')}))

      items.unshift({ text : this.$t('Delete') , value : null })
      return items


    },
    pricesSelectFiltered() {
      return this.pricesSelect.filter(el => el.device_type === this.selectedType)
    },
    coursesHeaders() {
      return [
        { text : this.$t('Course_')    , value : 'name' },
        { text : this.$t('Price')   , value : 'price', width : 5 },
        { text : 'Apple'  , value : 'iphone_price' , width : 5,},
        { text : 'Android'  , value : 'android_price' , width : 5},
        { value : 'is_setup', width : 5 },
      ]
    },

    pricesHeaders() {
      return [
        { text : this.$t('Name')    , value : 'name' },
        { text : this.$t('Price')   , value : 'price' },
        { text : this.$t('Bundle')  , value : 'bundle' },
        { text : this.$t('IsPublished')  , value : 'is_active' , width : 10 },
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('Prices') , value : 'prices'},
        { text : this.$t('Courses') , value : 'courses'},
      ]
    },
  },
  methods : {
    ...mapActions('mobileapps' , [
        'GET_MOBILE_IN_APP_PRODUCTS',
        'ADD_EDIT_MOBILE_IN_APP_PRODUCT',
        'EDIT_COURSE_MOBILE_PRICE',
        'GET_COURSES_MOBILE_PRICES'
    ]),


    async setCoursePrice(price , course, deviceType) {

      const data = {
        device_type : deviceType,
        course_id : course.uuid,
        price_id : price,
        plan_id : course.plan_id,
      }

      let result = await this.EDIT_COURSE_MOBILE_PRICE(data)

      if (!result) {
        return this.ERROR()
      }

      let index = this.courses.findIndex(el => el.uuid === course.uuid && el.plan_id === course.plan_id )
      course[deviceType.toLowerCase() + '_price'] = result === true ? null : result
      this.courses[index] = course
      this.courses = this.COPY(this.courses)

    },
    openAddPrice() {
      this.entityData = {}
      this.displayPriceDialog = true
    },
    openEditPrice(item) {
      this.entityData = this.COPY(item)
      this.displayPriceDialog = true
    },
    async addEditPrice() {

      if (!this.entityData.price) {
        this.priceError = true
        return this.notify(this.$t('EnterPrice') , 'warning')
      }

      if (!this.entityData.id && !this.entityData.device_type) {
        this.entityData.device_type = this.selectedType
      }

      let result = await this.ADD_EDIT_MOBILE_IN_APP_PRODUCT(this.entityData)

      if (!result) {
        return this.ERROR()
      }

      if (!this.entityData.id) {
        this.pricesSelect.push(this.COPY(this.entityData))
      } else {
        let index = this.pricesSelect.findIndex(el => el.id === this.entityData.id)
        if (index === -1) {
          return
        }
        this.pricesSelect[index] = result
        this.pricesSelect = this.COPY(this.pricesSelect)
      }

      this.displayPriceDialog = false
    },

    async getCourses() {
      let result = await this.GET_COURSES_MOBILE_PRICES()
      if (!result)  {
        return this.ERROR()
      }

      this.courses = result
    },

    async getMobileProducts() {
      let result = await this.GET_MOBILE_IN_APP_PRODUCTS()
      if (!result)  {
        return this.ERROR()
      }
      this.pricesSelect = result
    },

    initPage() {
      this.getCourses()
      this.getMobileProducts()




    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>
